body {
  margin: 0;
  padding: 1rem;
  padding: 20px;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  -webkit-font-smoothing: antialiased;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.button {
  font-size: larger;
  border: none;
  outline: none;
  background: transparent;
  color: black;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  height: fit-content;
  width: fit-content;
  padding: 5px;
  cursor: pointer;
}

.roulette-button {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: +9;
  border-radius: 5rem;
  width: 5rem;
  height: 5rem;
  transform: translate(-55%, -55%);
  background: white;
  color: #000;
  font-size: 0.8rem;
  font-weight: bold;
}

.input {
  border: 1px solid #dcdcdc;
  margin-right: 10px;
  height: 2rem;
  border-radius: 5px;
  min-width: 200px;
  padding-left: 15px;
  flex: 1;
}

.img-input {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}

.img-content {
  position: relative;
  width: 80px;
  height: 45px;
  object-fit: cover;
  margin-right: 15px;
  position: relative;
}

.item {
  padding: 10px 0;
  display: flex;
  align-items: center;
}

.text-title {
  font-size: 1.25rem;
  color: #421a4c;
  margin-bottom: 2.5rem;
}

.result-item {
  display: flex;
  align-items: center;
}

.result-text {
  color: #5b627d;
  font-size: 1.25rem;
}

.result-image {
  width: 1rem;
  height: 1rem;
  object-fit: contain;
  margin-right: 25px;
  border-radius: 8px;
  display: block;
}

.hidden {
  display: none;
}

i,
svg {
  margin-right: 1rem;
}
#root {
  font-family: sans-serif;
  text-align: center;
}

.text-title {
  font-size: 1.25rem;
  color: #5b627d;
  padding-top: 15px;
  padding-bottom: 15px;
}

.prize-message {
  font-size: 1rem;
  border: 1px solid #dcdcdc;
  padding: 20px 35px;
  border-radius: 0.5rem;
  color: #fff;
  max-width: 450px;
  margin: 0 auto;
  cursor: pointer;
  background: #7eb62e;
  background: rgb(35, 155, 99);
  background: linear-gradient(
    0deg,
    rgba(35, 155, 99, 1) 0%,
    rgba(100, 176, 49, 1) 100%
  );
}

.roulette-container {
  position: relative;
  width: 100%;
  max-width: 550px;
  aspect-ratio: 1;
  margin: 0 auto;
}

.roulette-container > div {
  width: 100%;
  height: 100%;
  max-width: unset;
  max-height: unset;
}

.main-form {
  max-width: 600px;
  margin: 0 auto;
}
ul {
  list-style: none;
  padding: 0;
}
ul > li {
  display: inline-block;
}
nav > ul > li:not(:first-child) {
  margin-left: 2rem;
}

a {
  color: black;
}

/* table {
  text-align: center;
  vertical-align: center;
  width: 100%;
  border: 1px solid lightgray;
}

td {
  border-bottom: 1px solid lightgray;
}

th {
  border-bottom: 1px solid lightgray;
  border-right: 1px solid lightgray;
  padding: 2px 4px;
} */

table {
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  width: 100%;
  border: 1px solid #eeeeee;
}

th {
  color: white;
  background: #000;
  padding: 18px 0;
  text-transform: uppercase;
}

tr,
td {
  padding: 18px 0;
}

tr:nth-of-type(odd) {
  background: #eeeeee;
}

h2 {
  font-size: 2rem
}